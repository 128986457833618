<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <h1 class="text-white my-4">Change securely!</h1>
        <h4 class="text-white font-weight-normal">
          Change your account password and make your self more securely El Warsha
          Dashboard 
        </h4>
      </div>
    </div>
    <form [formGroup]="changepasswordform" class="auth-side-form"
     (ngSubmit)="saveChangePassword()">
      <div class="auth-content">
        <img
          src="assets/images/auth/auth-logo-dark.png"
          alt=""
          class="img-fluid mb-4 d-block d-xl-none d-lg-none"
        />
        <div class="input-group mb-4">
          <h4 class="mb-4 f-w-400">{{'labels.changepassword' |translate}}</h4>
          <div class="input-group mb-2">
            <input
              formControlName="old_password"
              [ngClass]="{
                'is-invalid':
                isFieldValid('old_password')
              }"
              type="password"
              class="form-control"
              [placeholder]="'forms.currentpassword' |translate"
            />
          </div>
          <app-field-error
          [displayError]="isFieldValid('old_password')"
          [errorMsg]="'errors.entervalidfield' |translate"
        ></app-field-error>
          <div class="input-group mb-2">
            <input
              formControlName="password"
              [ngClass]="{
                'is-invalid':
                isFieldValid('password')
              }"
              type="password"
              class="form-control"
              [placeholder]="'forms.newpassword' |translate"
            />
          </div>
          <app-field-error
          [displayError]="isFieldValid('password')"
          [errorMsg]="'errors.entervalidfield' |translate"
        ></app-field-error>
          <div class="input-group mb-4">
            <input
              formControlName="password_confirmation"
              [ngClass]="{
                'is-invalid':
                isFieldValid('password_confirmation')
              }"
              type="password"
              class="form-control"
              [placeholder]="'forms.retypenewpassword' |translate"
            />
          </div>
          <app-field-error
          [displayError]="
          !passwordMatches &&
          changepasswordform.get('password_confirmation').touched
        "    
        [errorMsg]="'errors.passworddoesntmatch' |translate"
        ></app-field-error>
        </div>
     
        <div form-submit-btn [btnLable]="'labels.changepassword' | translate"
        [formInvalid]="changepasswordform.invalid"
        [isLoading]="isLoading"
        type="submit"
        >
      </div>
        <a
        class="btn btn-block btn-danger text-white mt-4"
        routerLink="/dashboard"
      >
        {{'buttons.back' |translate}} 
      </a>
      </div>
      
    </form>
   
  </div>
</div>
