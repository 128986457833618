import { AuthAdminService } from './../../../../auth/shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { MessagingService } from 'src/app/shared/services/notification/notification.service';
import { Observable } from 'rxjs';
import { DisplayNotificationsService } from '../../../../shared/services/display-notifications/display-notifications.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
})
export class NavRightComponent implements OnInit {
  title = 'push-notification';
  message;
  notifications = [];
  readNotifications = [];
  user;
  public unreadNotifications$: Observable<number>;
  private allNotifications = [];

  constructor(
    private messagingService: MessagingService,
    private authService: AuthAdminService,
    private displayNotifi: DisplayNotificationsService
  ) {
    this.messagingService.currentMessage.subscribe((msg) => {
      this.message = msg;
      this.unreadNotifications$ = this.displayNotifi.unreadNotificationsSubject;
    });
  }
  ngOnInit() {
    this.user = this.authService.User;
    this.getNotifications();
  }

  private getNotifications() {
    this.displayNotifi.notificationsSubject.subscribe((res) => {
      if (res) {
        this.allNotifications = res;
        this.setUnreadAndRead(res);
      }
    });
  }

  private setUnreadAndRead(allNotifications) {
    this.notifications = allNotifications.filter(
      (notification) => notification.read_at === null
    );
    this.readNotifications = allNotifications.filter(
      (notification) => notification.read_at !== null
    );
  }

  markAllNotificationsAsRead() {
    this.displayNotifi.markAllNotificationsAsRead();
    if (this.notifications.length) {
      this.notifications.forEach((notification) => {
        notification.read_at = 1;
      });
      this.setUnreadAndRead(this.allNotifications);
    }
  }

  loadMoreNotifications() {
    this.displayNotifi.loadMoreNotifications();
  }

  markNotificationAsRead(notificationId: string) {
    this.displayNotifi.markNotificationAsRead(notificationId);
  }

  getNotificationDate(date: string): string {
    return (
      new Date(date).toLocaleTimeString() +
      ', ' +
      new Date(date).toLocaleDateString()
    );
  }

  public get seeMore() {
    return this.displayNotifi.seeMore;
  }
  logout() {
    this.authService.logout()
    // this.authService.logout().subscribe((res) => {
    //   console.log(res);
    // });
  }
}
