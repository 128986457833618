import { Ban } from './../model';
import { HttpClientService } from './../../../shared/http-confing/http/http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Response } from '../../../shared/models/response.model';
import { EndPoints } from '../../../shared/endpoints/endpoints';


@Injectable({
	providedIn: 'root'
})
export class AuthBanService {
	apiURLBan = 'ban';


	constructor(private https: HttpClientService, private router: Router) { }


	create(ban): Observable<Response<Ban>> {
		return this.https.post(ban,this.apiURLBan );
	}



}
