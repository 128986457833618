<div class="form-group">
    <!-- <label for="exampleInputEmail1">{{"buttons.search" | translate}}</label> -->
    <input
     
    [formControl]="keyword"
    (input)="save()"
    [ngClass]="{
        'is-invalid':emptyList
      }"
      type="text"
      class="form-control"
      [placeholder]="'buttons.search' | translate"
    />
    <app-field-error [displayError]="emptyList" [errorMsg]="('errors.nofound' |translate ) +'  '+ name "></app-field-error>

  
</div>