import { HttpClientService } from './../../http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SendnotificationService {

  constructor(private http: HttpClientService) {}


  public getCustomerNotifications(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get('notification/get-customers-notifications' , {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }


  public createCustomerNotifications(body){
    return this.http.post( body ,  'notification/send-general-customers-notification'  )
  }

 
  public getTechnicianNotifications(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`notification/get-technicians-notifications`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }

  public createTechnicianNotifications(body){
    return this.http.post( body ,  'notification/send-general-technicians-notification'  )
  }

}
