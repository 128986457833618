import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HttpClientService } from '../http-confing/http/http-client.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  constructor(private https: HttpClientService) {}
  private subject = new Subject<any>();

  swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

   afterSubmitToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  
  successMessage(messageKey: string): void {
    this.afterSubmitToast.fire({
      icon: 'success',
      title: messageKey
    }) 
   }

  errorMessage(messageKey: string): void {
    this.afterSubmitToast.fire({
      icon: 'error',
      title: messageKey
    })   }

  delete( url:string  ,  id:number , index:number ) {
    
    this.swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete It',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
      return this.https.post({id:id}, `${url}/delete`).subscribe(res=>{
      })
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.swalWithBootstrapButtons.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })
  }
}
