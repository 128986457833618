import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import {
  LogInCredentials,
  LogInResponse,
} from '../model/login-credentials.model';
import { Response } from '../../../shared/models/response.model';
import { EndPoints } from '../../../shared/endpoints/endpoints';
import { HttpClientService } from 'src/app/shared/http-confing/http/http-client.service';
import { MessagingService } from 'src/app/shared/services/notification/notification.service';


export interface IUser {
  id: number;
  name: string;
  phone_verified_at: string;
  token: string;
  firebase_token: string;
  user_type: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthAdminService {
  apiURLLogIn = EndPoints.LOG_IN_ENDPOINT;
  apiURLLogout = EndPoints.LOGOUT_ENDPOINT;

  public expiredTokenSubject: Subject<boolean>;
  public redirectUrl: string;

  constructor(private https: HttpClientService, 
    private router: Router , 
    private messagingService: MessagingService) {
    this.expiredTokenSubject = new Subject();
    
  }
  
  signIn(
    logInCredentials: LogInCredentials
    ): Observable<Response<LogInResponse>> {
      if (this.messagingService.firebaseToken) {
      logInCredentials.firebase_token = this.messagingService.firebaseToken;
    }
    return this.https.post(logInCredentials, this.apiURLLogIn);
  }
  
  
    public set User(user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  
    public get User(): IUser {
      return JSON.parse(localStorage.getItem('user'));
    }
    
    removeUser() {
      localStorage.removeItem("user");
      this.router.navigate(["/auth/signin"]);
    }
    
    public get Token() {
      if (this.User) {
        return this.User.token;
      }
    }
    public get isLoggedIn() {
      if (!this.User) {
        return false;
      }
      
      return true;
    }
    
  // public logout() {
  //   const body: { firebase_token?: string } = {};
  //   if (this.messagingService.firebaseToken) {
  //     body.firebase_token = this.messagingService.firebaseToken;
  //   }
  //   return this.https.post(body , this.apiURLLogout).pipe(
  //     tap(
  //       (_) => this.removeUser(),
  //       (_) => this.removeUser()
  //     )
  //   );
  // }
  
  public logout() {
    const body: { firebase_token?: string } = {};
    if (this.messagingService.firebaseToken) {
      body.firebase_token = this.messagingService.firebaseToken;
    }
    this.https.post(body, "auth/logout").subscribe(
      (_) => this.removeUser(),
      (_) => this.removeUser()
    );
  }
  


  // isLogged() {
  //   return this.getToken() ? true : false;
  // }

  // storeData(data): void {
  //   localStorage.setItem(this.tokenKey, data);
  // }

  // getData(): any {
  //   return localStorage.getItem(this.tokenKey);
  // }
}
