import { AuthAdminService } from './../../../auth/shared/services/auth.service';
import { FacadeService } from './../../services/facade.service';
import { AuthBanService } from './../../../auth/shared/services/ban.service';
import { HttpEvent, HttpRequest, HttpHandler, HttpResponse, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class Interceptor implements HttpInterceptor {
	constructor(private _auth: AuthAdminService, private route: ActivatedRoute, private router: Router, private facade: FacadeService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let request = req.clone({});
		// if token exits will be added to request headers

		if (this._auth.Token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${this._auth.Token}`,
				},
			});
		}
		return next.handle(request).pipe(
			tap(
				(event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {
					}
				},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						console.log(err);
						if (err.error.message === "Unauthenticated.") {
							this._auth.removeUser();
							this._auth.expiredTokenSubject.next(true);
						}
					}
					return of(err);
				}
			)
		);
	}
}
