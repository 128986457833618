import { HttpClientService } from 'src/app/shared/http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClientService) {}
  

  public GetPartReports(body) {
    return this.http.post(body, `next-day-orders/all`);
  }

  public GetServiceReports(body) {
    return this.http.post(body, `service-sales/all`);
  }

  public getTotalReports(){
    return this.http.get(  'total-orders-report/all'  )
  }
  public getTotalReportsFiltered(body){
    return this.http.get(  'total-orders-report/all' , body )
  }
} 
