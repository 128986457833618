import { HttpClientService } from './../../../shared/http-confing/http/http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Response } from '../../../shared/models/response.model';
import { EndPoints } from '../../../shared/endpoints/endpoints';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  apiURLCompanyEndpoint = EndPoints.COMPANY_ENDPOINT;

  constructor(private https: HttpClientService, private router: Router) { }

  getCompanyData(): Observable<any> {
    return this.https.get(`${this.apiURLCompanyEndpoint}/data`);
  }

  getAllDiscounts(page: number): Observable<any> {
    return this.https.get(`${this.apiURLCompanyEndpoint}/getHistoryCompany`, { page: page })
  }

  // changePassword(body: {
  //   password: number;
  //   old_password: number;
  //   password_confirmation: number;
  //   user_id?: number;
  // }): Observable<Response<any>> {
  //   return this.https.post(body, this.apiURLCompanyEndpoint);
  // }
}