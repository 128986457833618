import { ViewProfileComponent } from './auth/view-profile/view-profile.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { LoggedInGuard } from './auth/shared/guards/logged-in.guard';
import { AuthComponent } from './layout/auth/auth.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { NotLoggedInGuard } from './auth/shared/guards/not-logged-in.guard';
import { PageNotFoundComponent } from './general/page-not-found/page-not-found.component';
// import { LoggedInGuard } from './auth/shared/guards';
import { UsersModule } from './users/users.module';
import { DiscountHistoryComponent } from './auth/discount-history/discount-history.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [NotLoggedInGuard],
    component: AdminComponent,
    // canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./general/dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then(
            (module) => module.UsersModule
          ),
      },
      {
        path: 'view-profile',
        component: ViewProfileComponent,
      }, {
        path: 'discount-history',
        component: DiscountHistoryComponent
      }
    ],
  },

  {
    path: '',
    component: AuthComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./auth/auth.module').then(
            (module) => module.AuthenticationModule
          ),
      },
    ],
  },
  {
    canActivate: [NotLoggedInGuard],
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
