import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  qParams?: Params;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'navigation.navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'users',
        title: 'navigation.users',
        type: 'item',
        icon: 'feather icon-users',
        url: '/users',
        qParams: {
          page: 1
        }
      },
      {
        id: 'discount_history',
        title: 'navigation.discount_history',
        type: 'item',
        icon: 'feather icon-pocket',
        url: '/discount-history',
        qParams: {
          page: 1
        }
      },
      {
        id: 'orders',
        title: 'navigation.orders',
        type: 'item',
        icon: 'feather icon-list',
        url: '/users/orders',
        qParams: {
          page: 1
        }
      },
      // {
      //   id: 'cars',
      //   title: 'navigation.cars',
      //   type: 'collapse',
      //   icon: 'feather icon-home',
      //   children: [
      //     {
      //       id: 'manufacturer',
      //       title: 'navigation.manufacturer',
      //       type: 'item',
      //       url: '/cars/manufacturer-list',
      //     },
      //     {
      //       id: 'model',
      //       title: 'navigation.model',
      //       type: 'item',
      //       url: '/cars/model-list',
      //     },
      //     {
      //       id: 'model-year',
      //       title: 'navigation.modelyear',
      //       type: 'item',
      //       url: '/cars/model/year-list',
      //     },
      //   ],
      // },
      // {
      //   id: 'regions',
      //   title: 'navigation.regions',
      //   type: 'collapse',
      //   icon: 'feather icon-home',
      //   children: [
      //     {
      //       id: 'country',
      //       title: 'navigation.country',
      //       type: 'item',
      //       url: '/region/country-list',
      //     },
      //     {
      //       id: 'city',
      //       title: 'navigation.city',
      //       type: 'item',
      //       url: '/region/region-list',
      //     },
      //     {
      //       id: 'area',
      //       title: 'navigation.areas',
      //       type: 'item',
      //       url: '/region/areas-list',
      //     },
      //   ],
      // },
      // {
      //   id: 'users',
      //   title: 'navigation.users',
      //   type: 'collapse',
      //   icon: 'feather icon-home',
      //   children: [
      //     {
      //       id: 'customer-list',
      //       title: 'navigation.customerlist',
      //       type: 'item',
      //       url: 'users/customer-list',
      //     },
      //     {
      //       id: 'operation-list',
      //       title: 'navigation.operationlist',
      //       type: 'item',
      //       url: 'users/operation-list',
      //     },
      //     {
      //       id: 'technician-list',
      //       title: 'navigation.technicianlist',
      //       type: 'item',
      //       url: 'users/technician-list',
      //     },
      //   ],
      // },
      // {
      //   id: 'orders',
      //   title: 'navigation.orders',
      //   type: 'collapse',
      //   icon: 'feather icon-home',
      //   children: [
      //     {
      //       id: 'coupon-list',
      //       title: 'navigation.coupon',
      //       type: 'item',
      //       url: 'order/coupon',
      //     },
      //     {
      //       id: 'slot-list',
      //       title: 'navigation.slot',
      //       type: 'item',
      //       url: 'order/slot-list',
      //     },
      //     {
      //       id: 'order-list',
      //       title: 'navigation.orders',
      //       type: 'item',
      //       url: 'order/order-list',
      //     },
      //     {
      //       id: 'assign-order',
      //       title: 'navigation.Assign-orders',
      //       type: 'item',
      //       url: 'order/order-list/assign',
      //     },
      //     {
      //       id: 'waiting-order',
      //       title: 'navigation.Waiting-orders',
      //       type: 'item',
      //       url: 'order/order-list/waiting',
      //     },
      //   ],
      // },
      // {
      //   id: 'services',
      //   title: 'navigation.services',
      //   type: 'collapse',
      //   icon: 'feather icon-home',
      //   children: [
      //     {
      //       id: 'navigation.services',
      //       title: 'navigation.services',
      //       type: 'item',
      //       url: '/services/list',
      //     },
      //     {
      //       id: 'spareparts-list',
      //       title: 'navigation.SpareParts',
      //       type: 'item',
      //       url: '/services/spareparts-list',
      //     },
      //   ],
      // },
      // {
      //   id: 'notifications',
      //   title: 'navigation.notifications',
      //   type: 'collapse',
      //   icon: 'feather icon-home',
      //   children: [
      //     {
      //       id: 'navigation.Customer-Notification',
      //       title: 'navigation.Customer-Notification',
      //       type: 'item',
      //       url: '/notification/customer-notification',
      //     },
      //     {
      //       id: 'navigation.technician-Notification',
      //       title: 'navigation.technician-Notification',
      //       type: 'item',
      //       url: '/notification/technician-notification',
      //     },
      //   ],
      // },
      // {
      //   id: 'reviews',
      //   title: 'navigation.reviews',
      //   type: 'collapse',
      //   icon: 'feather icon-home',
      //   children: [
      //     {
      //       id: 'navigation.compliant',
      //       title: 'navigation.compliant',
      //       type: 'item',
      //       url: '/review/complaint-list',
      //     },

      //   ],
      // },
      // {
      //   id: 'reports',
      //   title: 'navigation.reports',
      //   type: 'collapse',
      //   icon: 'feather icon-home',
      //   children: [
      //     {
      //       id: 'navigation.PartsReport',
      //       title: 'navigation.PartsReport',
      //       type: 'item',
      //       url: '/reports/parts',
      //     },
      //     {
      //       id: 'navigation.ServicesReport',
      //       title: 'navigation.ServicesReport',
      //       type: 'item',
      //       url: '/reports/services',
      //     },
      //   ],
      // },
      // {
      //   id: 'companies',
      //   title: 'navigation.companies',
      //   type: 'item',
      //   icon: 'feather icon-home',
      //   url: '/companies',
      //   qParams: {
      //     page: 1
      //   }

      // },
      // {
      //   id: 'membership',
      //   title: 'navigation.memberships',
      //   type: 'item',
      //   icon: 'feather icon-home',
      //   url: '/membership',

      // },
      // {
      //   id: 'payment',
      //   title: 'navigation.payment',
      //   type: 'item',
      //   icon: 'feather icon-home',
      //   url: '/payment',

      // },
      // {
      //   id: 'privacy',
      //   title: 'navigation.privacy',
      //   type: 'item',
      //   icon: 'feather icon-home',
      //   url: '/privacy',

      // },
      // {
      //   id: 'terms',
      //   title: 'navigation.terms',
      //   type: 'item',
      //   icon: 'feather icon-home',
      //   url: '/terms',

      // },
    ],
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
