import { ChangePasswordCredentials } from './../model/change-password.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Response } from '../../../shared/models/response.model';
import { EndPoints } from '../../../shared/endpoints/endpoints';
import { HttpClientService } from 'src/app/shared/http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordService {
  apiURLChangePassword = EndPoints.CHANGE_PASSWORD_ENDPOINT;

  constructor(private https: HttpClientService, private router: Router) {}

  changePassword(body: {
    password: number;
    old_password: number;
    password_confirmation: number;
    user_id?: number;
  }): Observable<Response<any>> {
    return this.https.post(body, this.apiURLChangePassword);
  }
}
