import { Injectable } from '@angular/core';
import { EndPoints } from 'src/app/shared/endpoints/endpoints';
import { HttpClientService } from 'src/app/shared/http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private http: HttpClientService) {}
  orderAPI = 'order';
  orderRecalculateAPI = 'order';
  orderHistoryAPI = 'order';

  public createOrder(body) {
    return this.http.post(body, `${this.orderAPI}/create`);
  }

  public getAllOrders(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.orderAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }
  
  public getWaitingOrders(
    isPaginate: boolean = false,
    waitingOrders?: number,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.orderAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      waiting_orders: waitingOrders,
      page,
      active,
      search,
    });
  }

  public getOrderDetails(orderId: number, isLocalization: boolean = false) {
    return this.http.get(`${this.orderAPI}/order-details`, {
      id: orderId,
      is_localization: `${+isLocalization}`,
    });
  }

  public getWaitingSlots(orderId:number){
    return this.http.get('order/slots' , {order_id:orderId})
  }
  public updateOrder(body) {
    return this.http.post(body, `${this.orderAPI}/update`);
  }

  public updateStatus(body) {
    return this.http.post(body, `${this.orderAPI}/update-status`);
  }
  public assignTechnician(body) {
    return this.http.post(body, `${this.orderAPI}/assign-technician`);
  }

  public deleteOrder(orderId: number) {
    return this.http.post({ id: orderId }, `${this.orderAPI}/delete`);
  }
  public statusStatistics() {
    return this.http.get(`${this.orderAPI}/status-statistics`);
  }
  public filterOrders(body) {
    return this.http.get(`${this.orderAPI}/filter`, body);
  }
  
  public recalculateOrders(body) {
    return this.http.get(this.orderRecalculateAPI, body);
  }
  public getOrderHisory(id) {
    return this.http.get(this.orderHistoryAPI, { user_id: id });
  }
  public deletespareFromOrd( orderiId:number  , serviceSparePartId: number ,   itemId: number ){
    return this.http.post( {order_id: orderiId , service_spare_part_id: serviceSparePartId , item_id: itemId} , "order/spare-parts/delete")
  }
  public gettechnicianByArea(AreaId: number) {
    return this.http.get('area/get', { id: AreaId })
}

  public confirmSlot(body){
    return this.http.post(body , 'order/confirm-slots' )
  }

  public getAllOrderVersions(orderId: number){
    return this.http.get('order-history/all' , {order_id: orderId})
  }

  public getOrderVersionData(Id : number){
    return this.http.get('order-history/get', {id:Id})
  }
}

export interface ImodelOrderOptions {
  page?: number;
  is_paginate: 1 | 0;
  slot_id?: number;
  to?: any;
  from?: any;
  code?: number;
  assigned?: any;
  status?: any;
  waiting_orders?: number;

}

