<div class="col-xl-12">
    <app-card [customHeader]="true" [options]="false">
        <div class="app-card-header d-flex align-items-center justify-content-between">
            <h4 class="d-inline mb-0">
                <i class="fas fa-ticket-alt m-r-5"></i>
                {{ "forms.Personal details" | translate }}
            </h4>
            <!-- <a class="btn btn-info float-right align-items-center rounded-lg f-14" ngbTooltip="btn-primary"
                style="display: flex; margin: 5px" [routerLink]="['/users/update/', id]">
                <i class="fas fa-edit m-r-5"></i>{{ "buttons.update" | translate }}
            </a> -->
        </div>
        <div *ngIf="empty" class="alert alert-danger text-center font-weight-bold" role="alert">
            {{'errors.nofound' |translate}} {{'forms.user' |translate}}
        </div>
        <div *ngIf="company && !empty">
            <!-- Name & Email -->
            <div class="row">
                <div class="col-md-4 col-12">
                    <h4 class="text-secondary">{{'forms.name' | translate}}</h4>
                    <h5 class="text-dark px-3">{{company.company_name}}</h5>
                </div>
                <div class="col-md-4 col-12">
                    <h4 class="text-secondary">{{'forms.Email' | translate}}</h4>
                    <h5 class="text-dark px-3" *ngIf="company.email">{{company.email}}</h5>
                    <h5 class="text-danger px-3" *ngIf="!company.email">No Email Added Yet</h5>
                </div>
                <div class="col-md-4 col-12">
                    <h4 class="text-secondary">{{'forms.phone' | translate}}</h4>
                    <h5 class="text-dark px-3">{{company?.user?.phone}}</h5>
                </div>
            </div>
            <!-- Phone & Verified_at
            <div class="row py-3">
                <div class="col-md-6 col-12">
                    <h4 class="text-secondary">{{'forms.phone' | translate}}</h4>
                    <h5 class="text-dark px-3">{{company.phone}}</h5>
                </div>
                <div class="col-md-6 col-12">
                    <h4 class="text-secondary">{{'forms.verified_at' | translate}}</h4>
                    <h5 class="text-dark px-3" *ngIf="phone_verified_at">{{company.phone_verified_at}}</h5>
                    <h5 class="text-danger px-3" *ngIf="!phone_verified_at">Phone not verified Yet</h5>
                </div>
            </div> -->
            <!-- Status -->
            <div class="row py-3">
                <div class="col-md-4 col-12 d-flex align-items-center">
                    <div>
                        <h4 class="text-secondary">{{'forms.status' | translate}}</h4>
                        <h5 class="px-3" *ngIf="company.active ===0">
                            <span class="badge badge-danger px-3 py-2">Banned</span>
                        </h5>
                        <h5 class="px-3" *ngIf="company.active ===1">
                            <span class="badge badge-success px-3 py-2">Active</span>
                        </h5>
                    </div>

                </div>
                <div class="col-md-4 col-12">
                    <h4 class="text-secondary">{{'forms.created_at' | translate}}</h4>
                    <h5 class="px-3">{{company?.created_at | date :'medium'}}</h5>
                </div>
                <div class="col-md-4 col-12">
                    <h4 class="text-secondary">{{'forms.key' | translate}}</h4>
                    <h5 class="px-3">{{company?.key }}</h5>
                </div>
            </div>
            <!-- Key & Discount -->
            <div class="row py-3">
                <div class="col-md-4 col-12 d-flex align-items-center">
                    <div>
                        <h4 class="text-secondary d-inline-block">{{'forms.discount' | translate}}
                        </h4>
                        
                        <h5 class="px-3" *ngIf="company.is_percentage === 0">
                            <span class="badge badge-info px-3 py-2">{{company.value | currency :' EGP '}}</span>
                        </h5>
                        <h5 class="px-3" *ngIf="company.is_percentage === 1">
                            <span class="badge badge-info px-3 py-2">{{company.value }} % </span>
                        </h5>                        
                    </div>
                </div>
                <div class="col-md-4 col-12 d-flex align-items-center">
                    <div>
                        <h4 class="text-secondary d-inline-block mt-2">{{'navigation.current_labour_discount' | translate}}
                        </h4>
                        <a class="px-2 btn-link" [routerLink]="['/discount-history']" [queryParams]="{page:1}">
                            <small>
                                ( Click to see history )
                            </small>
                        </a>
                        <h5 class="px-3" *ngIf="company.is_labour_percentage === 0">
                            <span class="badge badge-info px-3 py-2">{{company.labour_discount | currency :' EGP '}}</span>
                        </h5>
                        <h5 class="px-3" *ngIf="company.is_labour_percentage === 1">
                            <span class="badge badge-info px-3 py-2">{{company.labour_discount }} % </span>
                        </h5>
                    </div>
                </div>
                
            </div>
        </div>

    </app-card>
</div>