import { HttpClientService } from 'src/app/shared/http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(private http: HttpClientService) {}

  public createMembership(body) {
    return this.http.postFormData(body, `membership/create`);
  }

  public getAllMembership(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string,
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`membership/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }

  public getMembership(MembershipId: number, isLocalization: boolean = false) {
    return this.http.get(`membership/get`, {
      id: MembershipId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateMembership(body) {
    return this.http.postFormData(body, `membership/update`);
  }

  public deleteMembership(MembershipId: number) {
    return this.http.post({ id: MembershipId }, `membership/delete`);
  }}
