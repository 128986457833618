import { FacadeService } from './../../services/facade.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: '[app-search]',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  keyword: FormControl;
  emptyList: boolean = false;
  @Input() url = "manufacturer";
  @Input() name;
  @Output() onSearch = new EventEmitter();

  constructor(private fb: FormBuilder,
    private facade: FacadeService,) { }

  // convenience getter for easy access to form fields

  ngOnInit(): void {
    this.createForm()
  }
  createForm(): void {
    this.keyword = new FormControl('');
  }
  save() {
    if (this.keyword.value) {
      this.facade.searchService.search(this.keyword.value, this.url).subscribe(res => {
        if (res.status == 200) {
          this.onSearch.emit(res.data)
          if (!res.data.length) {
            this.emptyList = true
            setTimeout(() => {
              this.emptyList = false
              this.keyword.reset()
            }, 1000);
           
          }
        }
      },
        err => {
          this.onSearch.emit('false')
        })
    } else {
      this.onSearch.emit('false')
    }

  }
}
