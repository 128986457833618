import { HttpClientService } from './../../http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrivacyAndTermsService {
  constructor(private http: HttpClientService) {}

  public getAllTerms(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get('policy/terms/get-all', {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }


  public getTerm(regionId: number, isLocalization: boolean = false) {
    return this.http.get('policy/terms/get', {
      id: regionId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateTerms(body) {
    return this.http.postFormData(body, 'policy/terms/update');
  }

 

  public getAllPrivacy(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get('policy/privacy/get-all', {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }


  public getPrivacy(regionId: number, isLocalization: boolean = false) {
    return this.http.get('policy/privacy/get', {
      id: regionId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updatePrivacy(body) {
    return this.http.postFormData(body, 'policy/privacy/update');
  }


}