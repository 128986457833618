import { Injectable } from '@angular/core';
import { urlSettings } from '../settings/urlSettings';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
  withCredentials: false,
};

@Injectable ({
  providedIn: 'root',
})
export class HttpClientService {
  private formData: FormData;

  constructor (private http: HttpClient) {
  }

  /**
   * a getter to return the required headers for Backend
   * X-CSRF-Token - application token from services/session/connect
   * Content-Type - the type of the request content.
   * Accept - forcing Backend to return the response as a json object
   * @return object of the headers
   */

  /**
   * getting token from Backend services module
   * @return http text token response
   */

  // setToken(): Observable<string> {
  //   return this.http.get(`${ERP.backEndUrl}oauth/token`, httpOptions).map(res => {
  //     localStorage.setItem("appToken", res.text())
  //     return res.text();
  //   });
  // }

  /**
   * building up the full url path for each resource and / or params
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return full request path after adding the entity type and resource param
   */
  fullRequestURL(resource: string | number): string {
    // return urlSettings.baseUrl + this.lng + '/v1/' + resource;
    return urlSettings.baseUrl + resource;
  }

  /**
   * basic http get request with headers.
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return http json response
   */
  get (resource?: string | number, params?: {}): Observable<any> {
    if ( params ) {
      resource += this.getArgs (params);
    }
    return this.http.get (this.fullRequestURL (resource), httpOptions);
  }

  /**
   * basic http post request with headers.
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @param body the contenct of the request
   * @return http json response
   */
  post (
    body: any = {},
    resource?: string | number,
    params?: {}
  ): Observable<any> {
    if ( params ) {
      resource += this.getArgs (params);
    }
    return this.http.post (this.fullRequestURL (resource), body, httpOptions);
  }

  /**
   * basic http post request with headers.
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @param body the contenct of the request
   * @return http json response
   */
  postFormData (
    body: any = {},
    resource?: string | number,
    params?: {}
  ): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders ({'Content-Type': 'multipart/form-data'}),
    };

    if ( params ) {
      resource += this.getArgs (params);
    }

    this.formData = new FormData ();
    this.setFormData (body);
    return this.http.post (this.fullRequestURL (resource), this.formData);

  }


  // postFormDataArrayFiles(
  //   body: any = {},
  //   files: File[],
  //   resource?: string | number,
  //   params?: {}
  // ): Observable<any> {
  //   if (params) {
  //     resource += this.getArgs(params);
  //   }

  //   let formdata = new FormData();
  //   Object.keys(body).forEach((key) => {
  //     formdata.append(key, body[key]);
  //   });

  //   if (files.length > 0) {
  //     Object.keys(files).forEach((file, index) => {
  //       formdata.append("file[" + index + "]", files[file]);
  //     });
  //   }

  //   let headers = new HttpHeaders();
  //   // headers = headers.set('Content-Type', 'multipart/form-data');

  //   const req = new HttpRequest(
  //     "POST",
  //     this.fullRequestURL(resource),
  //     formdata,
  //     {
  //       reportProgress: true,
  //       responseType: "json",
  //       headers: headers,
  //     }
  //   );
  //   return this.http.request(req);
  // }

  /**
   * basic http put request with headers.
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @param body the contenct of the request
   * @return http json response
   */
  put (body: any = {}, resource?: string | number): Observable<any> {
    return this.http.put (this.fullRequestURL (resource), body);
  }

  /**
   * basic http delete request with headers.
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return http json response
   */
  delete (params: any = {}, resource?: string | number): Observable<any> {
    if ( params ) {
      resource += this.getArgs (params);
    }
    return this.http.delete (this.fullRequestURL (resource), httpOptions);
  }

  /**
   * Serializin arguments as a string
   * @param options object of Backend parametars to serialize
   * @return string of parameters
   */
  getArgs (options: any): string {
    if ( !options ) {
      return '';
    }
    let args = '?';
    Object.keys (options).forEach ((key, index) => {
      args += this.optionToString (key, options[key]);
    });
    return args;
  }

  /**
   * Check if variable is array of objects
   * @param value array to check
   */
  private isArray(value) {
    return Object.prototype.toString.call(value) === "[object Array]";
  }

  /**
   * serializing eatch option
   * @param key option key
   * @param value option value
   * @return single option serilization
   */
  optionToString (key: string, value: any): string {
    if ( !value ) {
      return '';
    }
    let str = '';
    if ( value instanceof Array ) {
      value.forEach ((element, index) => {
        str += `${key}[${index}]=${element}&`;
      });
    } else if ( value instanceof Object ) {
      Object.keys (value).forEach ((element, index) => {
        if ( value instanceof Object ) {
          str += this.serializeObject (value[element], `${key}[${element}]`);
        } else {
          str += `${key}[${element}]=${value[element]}&`;
        }
      });
    } else {
      str += `${key}=${value}&`;
    }
    return str;
  }

  /**
   * serializing the object keys
   * @param obj object to serialize
   */
  private serializeObject(obj: any, parentSerialized: string): string {
    let str = "";
    Object.keys(obj).forEach((key, index) => {
      const value = obj[key];
      if (value instanceof Object) {
        str += `${this.serializeObject(value, `${parentSerialized}[${key}]`)}`;
      } else {
        str += `${parentSerialized}[${key}]=${value}&`;
      }
    });
    return str;
  }

  private setFormData(data: any, keys: string[] = [], index?: number): FormData {
    if (data instanceof Array) {
      data.forEach((el, i) => {
        this.setFormData(el, keys, i);
      });
    } else if (data instanceof Object && !data.lastModified) {
      Object.keys(data).forEach(elKey => {
        if (data[elKey] !== null && data[elKey] !== undefined) {
          keys.push(elKey);
          this.setFormData(data[elKey], keys);
          keys.splice(keys.length - 1, 1);
          }
      });
    } else {
      if (index !== null && index !== undefined) {
        this.formData.append(this.getKeys(keys) + `[${index}]`, data);
      } else {
        this.formData.append(this.getKeys(keys), data);
      }
      return this.formData;
    }
  }

  private getKeys(keys: string[]): string {
    let keysString: string;
    keys.forEach((key, i) => {
      if (i === 0) {
        keysString = key;
      } else {
        keysString = `${keysString}[${key}]`;
      }
    });
    return keysString;
  }


  // return formdata from normal object
//   private toFormData(body): FormData {
//     Object.keys(body).forEach((key) => {
//       if (body[key]) {
//         if (Array.isArray(body[key])) {
//           this.arrayToFormData(body[key], key);
//         } else if ((body[key]) instanceof Object && !body[key].lastModified) {
//           Object.keys(body[key]).forEach((el ) => {
//             if (body[key][el]) {
//               this.formData.append(key + `[${el}]`, body[key][el]);
//             }
//           });
//         } else {
//           this.formData.append(key, body[key]);
//         }
//       }
//     });
//     return this.formData;
//     }
//     private arrayToFormData(array, arrayName) {
//       if (array.length) {
//         array.forEach((el, i) => {
//           if (el instanceof Object) {
//             Object.keys(el).forEach((elKey) => {
//               this.formData.append(
//                 arrayName + `[${i}][${elKey}]`,
//                 JSON.stringify(el[elKey])
//                 );
//             });
//           } else {
//             if (el !== null && el !== undefined) {
//               this.formData.append(arrayName + `[${i}]`, JSON.stringify(el));
//             }
//           }
//         });
//       }
//     }
}
