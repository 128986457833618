import { FacadeService } from 'src/app/shared/services/facade.service';
import { ChangePasswordService } from './../shared/services/change-password.service';
import { Router } from '@angular/router';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ChangePasswordCredentials } from './../shared/model/change-password.model';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormManage } from 'src/app/shared/custom-validators';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends FormManage implements OnInit {
  changepasswordform: FormGroup;
  submitted: boolean = false;
  isLoading: boolean = false;
  errors;
  passwordMatches: boolean;
  confirmPasswordSubscription: Subscription;
  confirmPassError;
  constructor(
    private fb: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private facad: FacadeService,
    private router: Router,
    private _location: Location,
    public translate:TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }
  backClicked() {
    this._location.back();
  }
  // convenience getter for easy access to form fields
  // get f() {
  //   return this.changePasswordForm.controls;
  // }

  // Reset password form and logic
  initForm() {
    this.changepasswordform = new FormGroup({
      old_password: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      password: new FormControl('', [Validators.required]),
      password_confirmation: new FormControl('', Validators.required),
    });
    this.setForm(this.changepasswordform);

    this.confirmPasswordSubscription = this.changepasswordform
      .get('password_confirmation')
      .valueChanges.subscribe((val) => {
        if (val === this.getControllerValue('password')) {
          this.passwordMatches = true;
        } else {
          this.passwordMatches = false;
        }
      });
  }
  saveChangePassword() {
    if (this.isFormValid) {
    this.isLoading=true

      this.changePasswordService.changePassword(this.FormValue).subscribe(
        (res) => {
          if (res.status === 200) {
            this.responseSuccess('Form Submitted Successfully')
          }
        },
        (err) => {
          this.errors = err?.error;
          this.responseFaild('Form Faild')
        }
      );
    }
  }
  responseSuccess(messageKey) {
    this.router.navigate(['/dashboard']);
    this.facad.notifyService.successMessage(messageKey)
    this.isLoading=false
  }
  responseFaild(messageKey) {
    this.facad.notifyService.errorMessage(messageKey)
    this.isLoading=false
  }
}
