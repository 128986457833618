import { FormSubmitBtnComponent } from './components/form-submit-btn/form-submit-btn.component';
import { AppInputComponent } from './components/app-input/app-input.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule, BreadcrumbModule, CardModule, ModalModule ,FieldErrorModule } from './components';
import { DataFilterPipe } from './components/data-table/data-filter.pipe';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { ApexChartComponent } from './components/chart/apex-chart/apex-chart.component';
import { ApexChartService } from './components/chart/apex-chart/apex-chart.service';
import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './components/toast/toast.service';
import { GalleryComponent } from './components/gallery/gallery.component';
import { LightboxModule } from 'ngx-lightbox';
import { AppInterceptorInterceptor } from './interceptors/jwt.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelectModule } from 'ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SearchComponent } from './components/search/search.component';
import { ReviewsComponent } from './components/reviews/reviews.component';

/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const MODULES = [AlertModule, BreadcrumbModule, CardModule, ModalModule,FieldErrorModule ]
const COMPONENTS = [AppInputComponent, FormSubmitBtnComponent,SearchComponent,ReviewsComponent]


@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    ...MODULES,
    HttpClientModule,
    BreadcrumbModule,
    ClickOutsideModule,
    LightboxModule,
    NgxPaginationModule,
    SelectModule
  ],
  exports: [
    TranslateModule,
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataFilterPipe,
    ...MODULES,
    ...COMPONENTS,
    UploadFileComponent,
    NgxPaginationModule,
    SelectModule,
    BreadcrumbModule,
    ClickOutsideModule,
    SpinnerComponent,
    ApexChartComponent,
    GalleryComponent,
    ToastComponent
  ],
  declarations: [
    DataFilterPipe,
    SpinnerComponent,
    ApexChartComponent,
    ToastComponent,
    GalleryComponent,
    UploadFileComponent,
    ...COMPONENTS,
    SearchComponent,
    

  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AppInterceptorInterceptor,
    //   multi: true
    // },
    ApexChartService,
    ToastService
  ]
})
export class SharedModule { }
