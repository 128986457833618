import { CompanyService } from './../shared/services/company.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit {
  public company;
  public empty: boolean = true;
  constructor(private companyService: CompanyService) { }

  ngOnInit(): void {
    this.getProfileData();
  }

  getProfileData() {
    this.companyService.getCompanyData().subscribe(res => {
      this.company = res.data;
      this.empty = false
      console.log(this.company)
    }, err => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Error while get user Details',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.getProfileData();
        }
      })
    });
  }

}
