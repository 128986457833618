import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http-confing/http/http-client.service';
import { EndPoints } from '../../endpoints/endpoints';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClientService) {}
  serivceAPI ='services';



 search(keyword ,url)
   {
    return this.http.get(`${url}/search`,{keyword:keyword});
  }
}
