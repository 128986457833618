import { AuthAdminService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {
	constructor(private authService: AuthAdminService, private router: Router) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	  ):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		let url: string = state.url;
	
		return this.checkLogin(url);
	  }
	
	  checkLogin(url: string): boolean {
		if (this.authService.isLoggedIn) {
		  return true;
		}
	
		// Store the attempted URL for redirecting
		this.authService.redirectUrl = url;
	
		// Navigate to the login page with extras
		this.router.navigate(["auth/signin"]);
		return false;
	  }
}
