<div class="col-xl-12">
    <div class="card" *ngIf="currentDiscount">
        <div class="row pb-0 table-active border-warning table-dark card-header justify-content-center align-items-center">
            <div class="col-md-4 col-12 text-center mr-md-5">
                <h2 class="mb-3 text-c-green">
                    {{ "navigation.current_discount" | translate }}
                </h2>
                <h3 *ngIf="currentDiscount?.is_percentage" class="jumbotron p-1">{{currentDiscount?.price}}%</h3>
                <h3 *ngIf="!currentDiscount?.is_percentage" class="jumbotron p-1">{{currentDiscount?.price |
                    currency:'EGP'}}</h3>
            </div>

            <div class="col-md-4 col-12 text-center">
              <h2 class="mb-3 text-c-green">
                  {{ "navigation.current_labour_discount" | translate }}
              </h2>
              <h3 *ngIf="currentDiscount?.is_labour_percentage" class="jumbotron p-1">{{currentDiscount?.labour_discount}}%</h3>
              <h3 *ngIf="!currentDiscount?.is_labour_percentage" class="jumbotron p-1">{{currentDiscount?.labour_discount |
                  currency:'EGP'}}</h3>
          </div>

        </div>
    </div>
    <app-card [customHeader]="true" [options]="false" *ngIf="discounts">
        <div class="app-card-header">
          <h5>
            <i class="fas fa-bars m-r-5"></i>
            {{ "navigation.company" | translate }}
            {{ "navigation.discounts_list" | translate }}
          </h5>
        </div>
        <div class="table-responsive" *ngIf="discounts">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ "forms.#" | translate }}</th>
                <th>{{ "forms.company_discounts" | translate }}</th>
                <th>{{ "forms.labour_discounts" | translate }}</th>
                <th>{{ "forms.created_at" | translate }}</th>
                <th>{{ "forms.updated_at" | translate }}</th>
                <th>{{ "forms.status" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let discount of discounts;let i = index">
                <td>
                  {{i+1}}
                </td>
                <td>
                  <span *ngIf="!discount.is_percentage"  class="font-weight-bold">                
                    {{discount?.price | currency:' EGP '}}
                  </span>
                  <span *ngIf="discount.is_percentage"  class="font-weight-bold">                
                   {{discount?.price}}%
                 </span>
                </td>
                <td>
                  <span *ngIf="!discount.is_labour_percentage"  class="font-weight-bold">                
                    {{discount?.labour_discount | currency:' EGP '}}
                  </span>
                  <span *ngIf="discount.is_labour_percentage"  class="font-weight-bold">                
                   {{discount?.labour_discount}}%
                 </span>
                </td>
                <td>
                  <span class="text-secondary font-weight-bold">
                    {{discount?.created_at }}
                  </span>
                </td>
                <td>
                  <span class="text-secondary font-weight-bold">
                    {{discount?.updated_at}}
                  </span>
                </td>
                <td>
                   <span *ngIf="discount.active" class="badge badge-pill badge-success text-white  font-weight-bold">
                     Active
                   </span>
                   <span *ngIf="!discount.active" class="badge badge-pill badge-danger text-white font-weight-bold">
                       Inactive
                     </span>
                 </td>
              </tr>
            </tbody>
          </table>
        </div>
    
        <app-alert type="light text-center text-danger font-weight-bold" *ngIf="!discounts.length">
          {{ "navigation.discounts_list" | translate }}
          {{'errors.not_found' |translate}}
        </app-alert>
      </app-card>
</div>