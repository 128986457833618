import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../shared/services/company.service';
import { IPagination } from '../../shared/models/response.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-discount-history',
  templateUrl: './discount-history.component.html',
  styleUrls: ['./discount-history.component.scss']
})
export class DiscountHistoryComponent implements OnInit {
  public currentDiscount;
  public discounts;
  pagination: IPagination = {
    currentPage: 0,
    itemsPerPage: 12,
    totalItems: 0
  }
  constructor(private ActivatedRoute: ActivatedRoute, private CompanyService: CompanyService) { }

  ngOnInit(): void {
    this.ActivatedRoute.queryParamMap.subscribe(qParam => {
      this.pagination.currentPage = parseInt(qParam.get('page'));
      this.getHistory()
    })
  }

  getHistory(): void {
    this.CompanyService.getAllDiscounts(this.pagination.currentPage).subscribe(res => {
      console.log(res.data)
      this.currentDiscount = res.data ? res.data[0] : null;
      this.discounts = res.data
    }, err => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Error while get Discounts',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.getHistory();
        }
      })
    })
  }

}
