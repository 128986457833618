import { AuthAdminService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
	constructor(private authService: AuthAdminService, private router: Router) {}
	canActivate(
	  next: ActivatedRouteSnapshot,
	  state: RouterStateSnapshot
	):
	  | Observable<boolean | UrlTree>
	  | Promise<boolean | UrlTree>
	  | boolean
	  | UrlTree {
	  return this.checkLogin();
	}
  
	checkLogin(): boolean {
	  if (!this.authService.isLoggedIn) {
		return true;
	  }
  
	  // Navigate to the login page with extras
	  this.router.navigate(["/dashboard"]);
	  return false;
	}

}
