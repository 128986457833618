const AUTH = 'auth';
const LOG_IN = 'login';
const CHANGE_PASSWORD = 'change-password';
const LOGOUT = 'logout';
const COMPANY = 'company'


export abstract class EndPoints {
  // public static BASE_URL = BASE_URL;
  // public static API_URL = API_URL;
  public static LOG_IN_ENDPOINT = `${COMPANY}/${LOG_IN}`;
  public static CHANGE_PASSWORD_ENDPOINT = `${AUTH}/${CHANGE_PASSWORD}`;
  public static LOGOUT_ENDPOINT = AUTH + LOGOUT;
  public static COMPANY_ENDPOINT = COMPANY;

}
