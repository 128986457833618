import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../../app/shared/http-confing/http/http-client.service';
import { EndPoints } from '../../endpoints/endpoints';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  constructor(private http: HttpClientService) {}
  regionAPI = 'region';

  public createRegion(body: {
    name_en: string;
    name_ar: string;
    country_id: number;
  }) {
    return this.http.post(body, `${this.regionAPI}/create`);
  }

  public getAllRegions(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.regionAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }

  public getAllCities(countryId: number) {
    return this.http.get(`${this.regionAPI}/all`, { country_id: countryId });
  }

  public getAllRegionsFilter(body) {
    return this.http.get(`${this.regionAPI}/all`, body);
  }

  public getRegion(regionId: number, isLocalization: boolean = false) {
    return this.http.get(`${this.regionAPI}/get`, {
      id: regionId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateRegion(body) {
    return this.http.postFormData(body, `${this.regionAPI}/update`);
  }

  public deleteRegion(regionId: number) {
    return this.http.post({ id: regionId }, `${this.regionAPI}/delete`);
  }
}


export interface ImodelRegionOptions {
  active?: number;
  country_id?: number;
  page?: number;
  is_paginate: 1 | 0;

}
